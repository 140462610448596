var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import Vue from "vue";
import AsyncStatus from "../../../utils/async";
import gql from "graphql-tag";
export default Vue.extend({
    fragments: {
        cart: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment Shipping_cart on Cart {\n        id\n        shippingOptions {\n          id\n          description\n        }\n      }\n    "], ["\n      fragment Shipping_cart on Cart {\n        id\n        shippingOptions {\n          id\n          description\n        }\n      }\n    "]))),
    },
    props: {
        value: {
            type: Object,
            default: undefined,
        },
        cart: {
            type: Object,
            required: true,
        },
    },
    data: function () {
        return {
            status: new AsyncStatus(),
        };
    },
    computed: {
        valueOrEmpty: function () {
            return this.value || {};
        },
        shippingOptions: function () {
            return this.cart.shippingOptions.map(function (option) { return ({
                value: option.id,
                label: option.description,
            }); });
        },
    },
    methods: {
        onSubmit: function () {
            var _this = this;
            this.status.run(this.$validator.validate().then(function (valid) {
                if (!valid) {
                    return;
                }
                _this.$emit("submit");
            }));
        },
        onInput: function (fieldName, fieldValue) {
            var _a;
            this.$emit("input", __assign(__assign({}, this.valueOrEmpty), (_a = {}, _a[fieldName] = fieldValue, _a)));
        },
    },
});
var templateObject_1;
