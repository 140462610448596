var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import gql from "graphql-tag";
import { mapState } from "vuex";
import mixins from "vue-typed-mixins";
import { createEmptyCartPersonalInformationEntry, } from "../../../data";
import SignInOrPersonalInformationForm from "../../ui/SignInOrPersonalInformationForm.vue";
import Shipping from "./Shipping.vue";
import Payment from "./Payment.vue";
import BankTransfer from "./BankTransfer.vue";
import HealthCoverForm from "./HealthCoverForm.vue";
import NotificationsMixin from "../../ui/NotificationsMixin";
import PersonalInformationLoading from "../general/PersonalInformationLoading.vue";
import createCheckoutPendingCartPurchase from "../../../api/mutations/createCheckoutPendingCartPurchase";
import getAbsoluteUrl from "../../../router/getAbsoluteUrl";
export default mixins(NotificationsMixin).extend({
    components: {
        SignInOrPersonalInformationForm: SignInOrPersonalInformationForm,
        Shipping: Shipping,
        Payment: Payment,
        HealthCoverForm: HealthCoverForm,
        PersonalInformationLoading: PersonalInformationLoading,
        BankTransfer: BankTransfer,
    },
    apollo: {
        viewerStudent: {
            query: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n        {\n          viewerStudent {\n            id\n            shippingAddress {\n              streetAddress1\n              streetAddress2\n              city\n              postcode\n              state\n              country\n            }\n            ...Payment_viewerStudent\n          }\n        }\n        ", "\n      "], ["\n        {\n          viewerStudent {\n            id\n            shippingAddress {\n              streetAddress1\n              streetAddress2\n              city\n              postcode\n              state\n              country\n            }\n            ...Payment_viewerStudent\n          }\n        }\n        ", "\n      "])), Payment.options.fragments.viewerStudent),
        },
        cart: {
            query: gql(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n        query CheckoutQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            requiresShipping\n            shippingOptions {\n              id\n            }\n            healthCovers {\n              __typename\n              id\n            }\n            total {\n              amount\n              currency\n            }\n            totalAud\n            extraPersonalFields\n            ...Payment_cart\n            ...Shipping_cart\n            ...HealthCoverForm_cart\n            ...BankTransfer_cart\n          }\n        }\n        ", "\n        ", "\n        ", "\n        ", "\n      "], ["\n        query CheckoutQuery($id: ID!) {\n          cart(id: $id) {\n            id\n            requiresShipping\n            shippingOptions {\n              id\n            }\n            healthCovers {\n              __typename\n              id\n            }\n            total {\n              amount\n              currency\n            }\n            totalAud\n            extraPersonalFields\n            ...Payment_cart\n            ...Shipping_cart\n            ...HealthCoverForm_cart\n            ...BankTransfer_cart\n          }\n        }\n        ", "\n        ", "\n        ", "\n        ", "\n      "])), Payment.options.fragments.cart, Shipping.options.fragments.cart, HealthCoverForm.options.fragments.cart, BankTransfer.options.fragments.cart),
            variables: function () {
                return {
                    id: this.cartId,
                };
            },
            skip: function () {
                return !this.cartId;
            },
        },
    },
    // TODO: Redirect to cart page if no cart
    data: function () {
        return {
            step: "personal",
            personalInformation: createEmptyCartPersonalInformationEntry(),
            shipping: undefined,
            billingFormDetails: undefined,
        };
    },
    computed: __assign({ extraFields: function () {
            if (!this.cart) {
                return [];
            }
            return this.cart.extraPersonalFields;
        } }, mapState(["cartId"])),
    mounted: function () {
        if (!this.cartId) {
            this.$router.push("/cart");
        }
    },
    methods: {
        onPersonalInformationSubmit: function () {
            if (!this.cart) {
                throw new Error("Invalid state, no cart");
            }
            if (this.cart.healthCovers.length > 0) {
                this.step = "health-cover";
            }
            else if (this.cart.requiresShipping) {
                this.step = "shipping";
            }
            else {
                this.step = "payment";
            }
        },
        onHealthCoverSubmit: function () {
            if (!this.cart) {
                throw new Error("Invalid state, no cart");
            }
            if (this.cart.requiresShipping) {
                this.step = "shipping";
            }
            else {
                this.step = "payment";
            }
        },
        onShippingSubmit: function () {
            this.step = "payment";
        },
        onProcessPayment: function (billingFormDetails) {
            return __awaiter(this, void 0, void 0, function () {
                var _a, saveForNextTime, alreadyInAustralia, purchasingAgent, personalInformation, data;
                var _b;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0:
                            if (!this.cart) {
                                throw new Error("Invalid state, no cart");
                            }
                            if (billingFormDetails.paymentType === "bank-transfer") {
                                this.step = "bank-transfer";
                                return [2 /*return*/];
                            }
                            _a = this.personalInformation, saveForNextTime = _a.saveForNextTime, alreadyInAustralia = _a.alreadyInAustralia, purchasingAgent = _a.purchasingAgent, personalInformation = __rest(_a, ["saveForNextTime", "alreadyInAustralia", "purchasingAgent"]);
                            return [4 /*yield*/, this.$apollo.mutate(createCheckoutPendingCartPurchase({
                                    cartId: this.cart.id,
                                    shippingId: this.cart.requiresShipping
                                        ? (_b = this.shipping) === null || _b === void 0 ? void 0 : _b.id
                                        : undefined,
                                    personalInformation: personalInformation,
                                    successUrl: getAbsoluteUrl(this.$store, this.$router, saveForNextTime
                                        ? "/purchase/save-for-next"
                                        : "/cart/checkout/success"),
                                    purchasingAgent: purchasingAgent,
                                }))];
                        case 1:
                            data = (_c.sent()).data;
                            if (!data) {
                                throw new Error("There was an error creating the purchase");
                            }
                            window.location.href = data.createCheckoutPendingCartPurchase.checkoutUrl;
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
var templateObject_1, templateObject_2;
