var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import Vue from "vue";
import gql from "graphql-tag";
import compact from "lodash/compact";
import AsyncStatus from "../../../utils/async";
import AddressFieldSet from "../../dashboard/AddressFieldSet.vue";
import { createEmptyAddress } from "../../../data";
import createBankTransferPendingCartPurchase from "../../../api/mutations/createBankTransferPendingCartPurchase";
import getAbsoluteUrl from "../../../router/getAbsoluteUrl";
export default Vue.extend({
    components: {
        AddressFieldSet: AddressFieldSet,
    },
    fragments: {
        cart: gql(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      fragment BankTransfer_cart on Cart {\n        id\n        requiresShipping\n      }\n    "], ["\n      fragment BankTransfer_cart on Cart {\n        id\n        requiresShipping\n      }\n    "]))),
    },
    props: {
        cart: {
            type: Object,
            required: true,
        },
        personalInformation: {
            type: Object,
            required: true,
        },
        shipping: {
            type: Object,
            default: undefined,
        },
    },
    data: function () {
        var fullName = compact([
            this.personalInformation.firstName,
            this.personalInformation.lastName,
        ]).join(" ");
        return {
            status: new AsyncStatus(),
            form: {
                billing: {
                    name: fullName,
                    address: createEmptyAddress(),
                },
                shipping: {
                    sameAsBilling: false,
                    name: fullName,
                    address: createEmptyAddress(),
                },
            },
        };
    },
    methods: {
        onSubmit: function () {
            return __awaiter(this, void 0, void 0, function () {
                var isValid, _a, sameAsBilling, shipping, billingAddress, shippingAddress, _b, saveForNextTime, alreadyInAustralia, purchasingAgent, personalInformation;
                var _this = this;
                return __generator(this, function (_c) {
                    switch (_c.label) {
                        case 0: return [4 /*yield*/, this.$validator.validate()];
                        case 1:
                            isValid = _c.sent();
                            if (!isValid) {
                                return [2 /*return*/];
                            }
                            _a = this.form.shipping, sameAsBilling = _a.sameAsBilling, shipping = __rest(_a, ["sameAsBilling"]);
                            billingAddress = this.form.billing;
                            shippingAddress = sameAsBilling
                                ? billingAddress
                                : shipping;
                            _b = this.personalInformation, saveForNextTime = _b.saveForNextTime, alreadyInAustralia = _b.alreadyInAustralia, purchasingAgent = _b.purchasingAgent, personalInformation = __rest(_b, ["saveForNextTime", "alreadyInAustralia", "purchasingAgent"]);
                            this.status.run((function () { return __awaiter(_this, void 0, void 0, function () {
                                var data;
                                var _a;
                                return __generator(this, function (_b) {
                                    switch (_b.label) {
                                        case 0: return [4 /*yield*/, this.$apollo.mutate(createBankTransferPendingCartPurchase({
                                                cartId: this.cart.id,
                                                shippingId: this.cart.requiresShipping
                                                    ? (_a = this.shipping) === null || _a === void 0 ? void 0 : _a.id
                                                    : undefined,
                                                personalInformation: personalInformation,
                                                purchasingAgent: purchasingAgent,
                                                billingAddress: billingAddress,
                                                shippingAddress: shippingAddress,
                                            }))];
                                        case 1:
                                            data = (_b.sent()).data;
                                            if (!data) {
                                                throw new Error("There was an error creating the purchase");
                                            }
                                            // if (isBankTransferResult(result)) {
                                            //   this.clearCart();
                                            window.location.href = getAbsoluteUrl(this.$store, this.$router, 
                                            // This is ok, because no bank transfer allowed for property purchase
                                            "/cart/checkout/bank-transfer/".concat(data.createBankTransferPendingCartPurchase.pendingPurchaseId));
                                            return [2 /*return*/];
                                    }
                                });
                            }); })());
                            return [2 /*return*/];
                    }
                });
            });
        },
    },
});
var templateObject_1;
